import axios from "axios";
import config from '../config';

const instance = axios.create({
    baseURL: config.MAIN_SITE_URL,
});
// Thêm một request interceptor
instance.interceptors.request.use(config => {
    const token = localStorage.getItem('accessToken');
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Thêm một response interceptor
instance.interceptors.response.use(response => {
    // Xử lý response thông thường
    return response.data;
}, error => {
    // Kiểm tra nếu lỗi là do token hết hạn hoặc lỗi xác thực
    if (error.response && error.response.status === 401) {
        // Xử lý lỗi xác thực
    }
    return Promise.reject(error);
});

export default instance;
