/**
 * WorkTrendChart Component
 * Component hiển thị biểu đồ thống kê tình hình làm việc theo ngày
 * Bao gồm 2 chỉ số: Thu nhập và Số tài khoản
 * Sử dụng thư viện ApexCharts để vẽ biểu đồ area
 */

import React from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { BarLoader } from 'react-spinners';
import config from '../../../config';

// Các hằng số cho style
const CHART_CONTAINER_STYLE = 'bg-white rounded-sm';
const CHART_HEADER_STYLE = 'border-b p-3 border-gray-200 text-sm font-semibold';
const CHART_TITLE = 'Thống Kê Tình Hình Làm Việc Theo Ngày';
const CHART_STYLE = 'bg-white rounded-lg';
const LOADING_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg';
const ERROR_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg text-red-500';
const NO_DATA_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg text-gray-500';

// Các hằng số cho biểu đồ
const CHART_COLORS = {
    REVENUE: '#00E396',    // Màu xanh lá cho thu nhập
    ACCOUNT: '#2a77f4'     // Màu xanh dương cho tài khoản
};

const SERIES_NAMES = {
    REVENUE: 'Thu Nhập',
    ACCOUNT: 'Tài Khoản'
};

/**
 * Chuẩn hóa dữ liệu cho biểu đồ
 * @param {Array} data - Dữ liệu thô từ Redux store
 * @returns {Array} Dữ liệu đã được chuẩn hóa
 */
const normalizeData = (data) => {
    // Lấy tất cả các ngày duy nhất
    const allDates = [...new Set(data.map(entry => entry.date))];
    allDates.sort((a, b) => new Date(a) - new Date(b));

    // Chuẩn hóa dữ liệu thu nhập và tài khoản cho mỗi ngày
    return allDates.map(date => {
        const entry = data.find(d => d.date === date);
        return {
            date,
            revenue: entry ? entry.revenue : 0,
            account: entry ? entry.account : 0,
        };
    });
};

/**
 * Cấu hình cho biểu đồ
 * @param {Array} categories - Danh sách các ngày cho trục x
 * @returns {Object} Cấu hình cho biểu đồ
 */
const getChartOptions = (categories) => ({
    chart: {
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150,
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350,
            },
        },
        height: 320,
        toolbar: {
            show: false,
        },
    },
    colors: [CHART_COLORS.REVENUE, CHART_COLORS.ACCOUNT],
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: 'smooth',
        width: [1.5, 1.5],
        dashArray: [0, 5],
        lineCap: 'round',
    },
    grid: {
        padding: {
            left: 0,
            right: 0,
        },
        strokeDashArray: 3,
    },
    markers: {
        size: 0,
        hover: {
            size: 0,
        },
    },
    fill: {
        type: 'gradient',
        gradient: {
            shade: 'dark',
            type: 'vertical',
            shadeIntensity: 0.1,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.4,
            opacityTo: 0.3,
            stops: [0, 90, 100],
        },
    },
    tooltip: {
        x: {
            format: "dd MMM 'yy",
        },
        y: {
            formatter: (value, { seriesName }) => {
                if (seriesName === SERIES_NAMES.REVENUE) {
                    return `$${parseFloat(value).toFixed(2)}`;
                }
                return value;
            },
        },
    },
    legend: {
        position: 'top',
        horizontalAlign: 'right',
    },
    xaxis: {
        type: 'category',
        categories,
    },
});

// Component hiển thị loading
const LoadingIndicator = () => (
    <div className={LOADING_CONTAINER_STYLE}>
        <BarLoader color="#3592e8" />
    </div>
);

// Component hiển thị lỗi
const ErrorIndicator = ({ message }) => (
    <div className={ERROR_CONTAINER_STYLE}>
        <p>Error loading chart data: {message}</p>
    </div>
);

// Component hiển thị không có dữ liệu
const NoDataIndicator = () => (
    <div className={NO_DATA_CONTAINER_STYLE}>
        <p>No chart data available</p>
    </div>
);

const WorkTrendChart = () => {
    // Lấy dữ liệu từ Redux store
    const rawData = useSelector(state => state.charts.workTrendChartData);
    const loading = useSelector(state => state.charts.loading.workTrendChart);
    const error = useSelector(state => state.charts.error.workTrendChart);

    // Hiển thị loading state
    if (loading) {
        return <LoadingIndicator />;
    }

    // Hiển thị error state
    if (error) {
        return <ErrorIndicator message={error} />;
    }

    // Hiển thị no data state
    if (!rawData || rawData.length === 0) {
        return <NoDataIndicator />;
    }

    // Chuẩn hóa dữ liệu
    const normalizedData = normalizeData(rawData);

    // Tạo dữ liệu series cho biểu đồ
    const seriesData = [
        {
            name: SERIES_NAMES.REVENUE,
            type: 'area',
            data: normalizedData.map(data => data.revenue),
        },
        {
            name: SERIES_NAMES.ACCOUNT,
            type: 'area',
            data: normalizedData.map(data => data.account),
        },
    ];

    // Tạo cấu hình biểu đồ
    const chartOptions = getChartOptions(normalizedData.map(data => data.date));

    return (
        <div className={CHART_CONTAINER_STYLE}>
            <div className={CHART_HEADER_STYLE}>
                {CHART_TITLE}
            </div>
            <ReactApexChart
                className={CHART_STYLE}
                options={chartOptions}
                series={seriesData}
                height="350"
            />
        </div>
    );
};

export default WorkTrendChart;
