import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../services/axios';

function AdminPayrollTable() {
    const [payrollData, setPayrollData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Get startDate from Redux store
    const { startDate } = useSelector(state => state.dateRange);

    // Extract the month and year from startDate
    const currentDate = new Date(startDate);
    const currentMonth = currentDate.getMonth() + 1;  // JavaScript months are 0-indexed
    const currentYear = currentDate.getFullYear();

    // Utility function to get the number of days in a given month and year
    const getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate();  // Month is 1-indexed for this function
    };

    // Get number of days in the selected month
    const daysInMonth = getDaysInMonth(currentYear, currentMonth);

    useEffect(() => {
        // Construct the API URL using the month and year
        const apiUrl = `/api/payroll/${currentMonth}/${currentYear}/`;

        axios.get(apiUrl)
            .then(response => {
                setPayrollData(response); // Set payroll data from the response
                setLoading(false);
            })
            .catch(() => {
                setError("Error fetching data");
                setLoading(false);
            });
    }, [currentMonth, currentYear]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!payrollData) {
        return <div>No data available</div>;
    }

    // Function to calculate total salary
    const calculateSalary = (baseSalary, totalDaysPresent, totalDaysInMonth) => {
        if (totalDaysInMonth === 0 || !baseSalary) return 0;

        // Convert baseSalary to a number, remove commas and 'VNĐ'
        const numericBaseSalary = parseFloat(baseSalary.replace(/,/g, '').replace(' VNĐ', '')) || 0;
        
        // Calculate daily salary and multiply by total days present
        const dailySalary = numericBaseSalary / totalDaysInMonth;
        const totalSalary = dailySalary * totalDaysPresent;

        return Math.round(totalSalary); // Round result to nearest integer
    };

    // Function to calculate bonus
    const calculateBonus = (baseBonus, totalRevenue) => {
        if (!baseBonus || !totalRevenue) return 0;

        // Convert baseBonus to a percentage (e.g., "10%" to 0.1)
        const bonusPercentage = parseFloat(baseBonus.replace('%', '')) / 100 || 0;
        const bonusInVND = bonusPercentage * totalRevenue * 23500; // Convert USD to VND

        return Math.round(bonusInVND); // Round to nearest integer
    };

    // Function to calculate total salary
    const calculateTotalSalary = (salary, bonus) => {
        return salary + bonus; // Add salary and bonus
    };

    // Initialize totals
    let totalDaysWorked = 0;
    let totalRevenue = 0;
    let totalSalary = 0;
    let totalBonus = 0;
    let totalFinalSalary = 0;

// Filter out employees with base_salary = 0 or base_salary is null/undefined
const filteredPayrollData = payrollData.filter(employee => {
    const baseSalary = employee.base_salary ? employee.base_salary.replace(/,/g, '').replace(' VNĐ', '') : null;
    const numericBaseSalary = parseFloat(baseSalary) || 0;
    return numericBaseSalary > 0;
});
    return (
        <div className="w-full overflow-x-auto">
            <div className="min-w-full inline-block align-middle">
                <div className="overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200 text-[10px] md:text-sm text-center">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="sticky left-0 bg-gray-50 border px-1 md:px-2 py-1 md:py-2 text-[10px] md:text-xs">Employee</th>
                                {[...Array(daysInMonth).keys()].map(day => (
                                    <th key={day} className="hidden md:table-cell border px-1 py-1 md:py-2 text-[10px] md:text-xs">{day + 1}</th>
                                ))}
                                <th scope="col" className="border px-1 md:px-2 py-1 md:py-2 text-[10px] md:text-xs">Tổng số công</th>
                                <th scope="col" className="border px-1 md:px-2 py-1 md:py-2 text-[10px] md:text-xs">Total Revenue (USD)</th>
                                <th scope="col" className="border px-1 md:px-2 py-1 md:py-2 text-[10px] md:text-xs">Lương (VNĐ)</th>
                                <th scope="col" className="border px-1 md:px-2 py-1 md:py-2 text-[10px] md:text-xs">Bonus (VNĐ)</th>
                                <th scope="col" className="border px-1 md:px-2 py-1 md:py-2 text-[10px] md:text-xs">Lương thực tế (VNĐ)</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredPayrollData.map((employee, idx) => {
                                const salary = calculateSalary(
                                    employee.base_salary, 
                                    employee.total_days_present, 
                                    employee.total_days_in_month
                                );

                                const bonus = calculateBonus(
                                    employee.base_bonus,
                                    employee.total_revenue
                                );

                                const totalEmployeeSalary = calculateTotalSalary(salary, bonus);

                                // Accumulate totals
                                totalDaysWorked += employee.total_days_present;
                                totalRevenue += employee.total_revenue;
                                totalSalary += salary;
                                totalBonus += bonus;
                                totalFinalSalary += totalEmployeeSalary;

                                return (
                                    <tr key={idx} className='text-[10px] md:text-xs border-b odd:bg-white even:bg-gray-50'>
                                        <td className="sticky left-0 bg-white border px-1 md:px-2 py-1 md:py-2 text-left">
                                            <div className="flex flex-col">
                                                <span className="font-medium">{employee.username}</span>
                                                {/* Hiển thị thông tin ngày công trên mobile */}
                                                <span className="md:hidden text-gray-500 text-[8px]">
                                                    Số công: {employee.total_days_present}
                                                </span>
                                                <span className="md:hidden text-gray-500 text-[8px]">
                                                    Revenue: $ {employee.total_revenue.toLocaleString('en-US')}
                                                </span>
                                            </div>
                                        </td>
                                        {employee.attendance_days.slice(0, daysInMonth).map((day, dayIdx) => (
                                            <td key={dayIdx} className="hidden md:table-cell border px-1 py-1 md:py-2">{day}</td>
                                        ))}
                                        <td className="border px-1 md:px-2 py-1 md:py-2">{employee.total_days_present}</td>
                                        <td className="border px-1 md:px-2 py-1 md:py-2">$ {employee.total_revenue.toLocaleString('en-US')}</td>
                                        <td className="border px-1 md:px-2 py-1 md:py-2">{salary.toLocaleString('vi-VN')}₫</td>
                                        <td className="border px-1 md:px-2 py-1 md:py-2">{bonus.toLocaleString('vi-VN')}₫</td>
                                        <td className="border px-1 md:px-2 py-1 md:py-2 text-blue-600 font-semibold">{totalEmployeeSalary.toLocaleString('vi-VN')}₫</td>
                                    </tr>
                                );
                            })}

                            {/* Total row */}
                            <tr className="bg-gray-200 font-bold text-black text-[10px] md:text-xs">
                                <td className="sticky left-0 bg-gray-200 border px-1 md:px-2 py-1 md:py-2">Tổng cộng</td>
                                {[...Array(daysInMonth).keys()].map(day => (
                                    <td key={day} className="hidden md:table-cell border px-1 py-1 md:py-2"></td>
                                ))}
                                <td className="border px-1 md:px-2 py-1 md:py-2 text-blue-600 font-semibold">{totalDaysWorked}</td>
                                <td className="border px-1 md:px-2 py-1 md:py-2 text-blue-600 font-semibold">$ {totalRevenue.toLocaleString('en-US')}</td>
                                <td className="border px-1 md:px-2 py-1 md:py-2 text-blue-600 font-semibold">{totalSalary.toLocaleString('vi-VN')}₫</td>
                                <td className="border px-1 md:px-2 py-1 md:py-2 text-blue-600 font-semibold">{totalBonus.toLocaleString('vi-VN')}₫</td>
                                <td className="border px-1 md:px-2 py-1 md:py-2 text-blue-600 font-semibold">{totalFinalSalary.toLocaleString('vi-VN')}₫</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default AdminPayrollTable;
