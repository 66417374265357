import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchiProxyData } from '../../../services/proxySlice';
import axios from 'axios';
import moment from 'moment';
import axioscustome from '../../../services/axios';
import { BarLoader } from 'react-spinners';

function IproxyControl() {
    const dispatch = useDispatch();
    const iProxyData = useSelector(state => state.proxy.iProxyData);
    const loading = useSelector(state => state.proxy.loading);
    const error = useSelector(state => state.proxy.error);

    const [buttonColors, setButtonColors] = useState({});
    const [currentIps, setCurrentIps] = useState({});
    const [lastChangeTimes, setLastChangeTimes] = useState({});
    const [clickedPorts, setClickedPorts] = useState([]);
    const [displayData, setDisplayData] = useState([]);

    useEffect(() => {
        setDisplayData(iProxyData);
    }, [iProxyData]);

    const fetchCurrentIps = async () => {
        const url = "https://iproxy.shop/api/proxyAll.php?key=0d346db6a02b99a5754820595b6f97ea89f925fae73c64899c55e9ddafd80deefd5df2ee4fd9aa0482e994f2a063cf8219ea02a0504f7d1dad7509d5f79c5fe3&rip=1";
        try {
            const response = await axios.get(url);
            const lines = response.data.split('\n');
            const ipMap = {};
            lines.forEach(line => {
                const parts = line.split(':');
                if (parts.length === 3) {
                    const proxyPort = parts[1];
                    const currentIp = parts[2];
                    ipMap[proxyPort] = currentIp;
                }
            });
            return ipMap;
        } catch (error) {
            console.error('Error fetching IPs:', error);
            return {};
        }
    };

    const fetchAndSetCurrentIps = useCallback(async () => {
        const ips = await fetchCurrentIps();
        setCurrentIps(ips);
    }, []);

    const formatLastChangeTime = (time) => {
        return time ? moment(time).fromNow() : 'Not available';
    };

    useEffect(() => {
        dispatch(fetchiProxyData());
        fetchAndSetCurrentIps();
        const interval = setInterval(() => {
            fetchAndSetCurrentIps();
        }, 30000);
        return () => clearInterval(interval);
    }, [dispatch, fetchAndSetCurrentIps]);

    const handleChangeIPClick = useCallback(async (proxyRotating, proxyId) => {
        const url = `https://iproxy.shop/api/${proxyRotating}`;
        try {
            const response = await axios.get(url);
            const { status } = response.data;
            setButtonColors(prevColors => ({ ...prevColors, [proxyId]: status === "ok" ? "green" : "red" }));
            if (status === "ok") {
                const currentTime = new Date().toISOString();
                const updateUrl = `/api/v1/iproxy/${proxyId}/`;
                await axioscustome.put(updateUrl, { proxy_lastchangeip: currentTime });
                setLastChangeTimes(prevTimes => ({ ...prevTimes, [proxyId]: currentTime }));
            }
            setTimeout(() => {
                setButtonColors(prevColors => ({ ...prevColors, [proxyId]: "default" }));
            }, 5000);
        } catch (error) {
            console.error('Error:', error);
            setButtonColors(prevColors => ({ ...prevColors, [proxyId]: "red" }));
            setTimeout(() => {
                setButtonColors(prevColors => ({ ...prevColors, [proxyId]: "default" }));
            }, 5000);
        }
        setClickedPorts(prevClickedPorts => [...prevClickedPorts, proxyId]);
        fetchAndSetCurrentIps();
    }, [fetchAndSetCurrentIps]);

    const getSvgForType = (proxyType) => {
        if (proxyType === 'lte') {
            return (
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                </svg>
            );
        }
        return (
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
            </svg>
        );
    };

    const handleSortProxies = () => {
        const sorted = [...iProxyData].sort((a, b) => clickedPorts.includes(b.id) - clickedPorts.includes(a.id));
        setDisplayData(sorted);
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-64">
                <BarLoader color="#3B82F6" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-64">
                <div className="text-red-500">Error loading data: {error.message}</div>
            </div>
        );
    }

    return (
        <div className="p-2">
            <div className="flex justify-between items-center p-2 mb-2 bg-white border-b border-gray-200 rounded-t-lg">
                <span className="text-sm font-semibold text-gray-800">iProxy Control</span>
                <button
                    onClick={handleSortProxies}
                    className="inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-white bg-yellow-500 rounded hover:bg-yellow-600 focus:ring-2 focus:outline-none focus:ring-yellow-300 transition-colors duration-200"
                >
                    <svg width="14" height="14" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.54163 12.0417H9.20829" stroke="white" strokeWidth="2" strokeLinecap="round" />
                        <path d="M3.54163 8.5H7.79163" stroke="white" strokeWidth="2" strokeLinecap="round" />
                        <path d="M3.54163 4.95837H6.37496" stroke="white" strokeWidth="2" strokeLinecap="round" />
                        <path d="M13.4584 4.25L15.5834 6.375M13.4584 4.25L11.3334 6.375M13.4584 4.25L13.4584 12.75" stroke="white" strokeWidth="2" />
                    </svg>
                    Sort Proxies
                </button>
            </div>

            <div className="h-[calc(100vh-200px)] overflow-y-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    {displayData.map(proxy => (
                        <div
                            key={proxy.id}
                            className={`bg-white p-2 rounded shadow-sm transition-all duration-200 ${
                                clickedPorts.includes(proxy.id)
                                    ? "border border-red-500 shadow"
                                    : "border border-gray-200"
                            }`}
                        >
                            <label className={`text-xs font-semibold flex items-center gap-1 ${
                                clickedPorts.includes(proxy.id) ? "text-red-500" : "text-gray-700"
                            }`}>
                                {getSvgForType(proxy.proxy_type)}
                                {proxy.proxy_mem}:{proxy.proxy_name}
                            </label>

                            <div className="flex items-center gap-1 mt-2">
                                <div className="w-32">
                                    <select 
                                        className="w-full h-7 px-2 text-xs border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-500"
                                        defaultValue="US"
                                    >
                                        <option value="US" selected>US</option>
                                    </select>
                                </div>
                                <input
                                    placeholder={currentIps[proxy.proxy_port] || ''}
                                    className="flex-1 h-7 px-2 text-xs border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-500"
                                    readOnly
                                />
                                <button
                                    className={`w-40 h-7 ml-2 px-2 text-white rounded-lg shadow-sm text-xs ${
                                        buttonColors[proxy.id] === "red" ? "bg-red-500" :
                                        buttonColors[proxy.id] === "green" ? "bg-green-500" : "bg-slate-400"
                                    } hover:bg-blue-600`}
                                    onClick={() => handleChangeIPClick(proxy.proxy_rotating, proxy.id)}
                                >
                                    Change IP
                                </button>
                            </div>

                            <div className="mt-1 text-[10px] text-gray-500">
                                Last Rotation: {formatLastChangeTime(lastChangeTimes[proxy.id] || proxy.proxy_lastchangeip)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default IproxyControl;