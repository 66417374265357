import CryptosTable from "./crypto/table-crypto";

function Crypto() {
    return (
        <div className="mt-12 md:mt-20">
            <div className="statistics">
                <div className="px-4 md:pl-5">
                    <div className="font-semibold text-sm md:text-base">
                        Crypto Check
                    </div>
                    <div className="text-[10px] md:text-xs text-gray-600">
                        Sử dụng các hành động thêm/sửa/xóa để thao tác
                    </div>
                </div>
                <div className="mt-4 md:mt-6">
                    <div className="w-full overflow-x-auto">
                        <div className="min-w-full">
                            <CryptosTable />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Crypto;