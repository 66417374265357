import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCryptosData } from '../../../services/cryptoSlice';
import axios from 'axios';
import custom_axios from '../../../services/axios';
import { GridLoader } from 'react-spinners';

function CryptosTable() {
    const dispatch = useDispatch();
    const { cryptosData, loading, error } = useSelector(state => state.cryptos);
    const [cryptosState, setCryptosState] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newCrypto, setNewCrypto] = useState({ cryptos_name: '', cryptos_quantity: '', cryptos_buymarket: '', cryptos_usdbuy: '', cryptos_usdsell: '' });
    const [totalCapital, setTotalCapital] = useState(0);
    const [totalVND, setTotalVND] = useState(0);

    useEffect(() => {
        const fetchTotalCapital = async () => {
            try {
                const response = await custom_axios.get('/api/totalcapital/');
                if (response && response) {
                    const totalAmount = response.reduce((sum, capital) => sum + parseFloat(capital.amount), 0);
                    setTotalCapital(totalAmount);
                    const totalVNDValue = response.reduce((sum, capital) => sum + parseFloat(capital.description), 0);
                    setTotalVND(totalVNDValue);
                }
            } catch (error) {
                console.error('Error fetching total capital:', error);
            }
        };

        const fetchData = async () => {
            try {
                const data = await dispatch(fetchCryptosData()).unwrap();
                const updatedData = await fetchCurrentPrices(data);
                setCryptosState(updatedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchTotalCapital();
        fetchData();
        const intervalId = setInterval(fetchData, 300000);
        return () => clearInterval(intervalId);
    }, [dispatch]);

    useEffect(() => {
        const updatePrices = async () => {
            const updatedData = await fetchCurrentPrices(cryptosData);
            setCryptosState(updatedData);
        };
        updatePrices();
    }, [cryptosData]);

    const fetchCurrentPrices = async (cryptos) => {
        try {
            const updatedCryptos = await Promise.all(
                cryptos.map(async (crypto) => {
                    try {
                        const response = await axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=${crypto.cryptos_name.toUpperCase()}USDT`);
                        const currentPrice = response.data.price ? parseFloat(response.data.price) : 0;

                        return {
                            ...crypto,
                            current_price: currentPrice
                        };
                    } catch (error) {
                        console.error(`Error fetching price for ${crypto.cryptos_name}:`, error);
                        return {
                            ...crypto,
                            current_price: 0
                        };
                    }
                })
            );

            return updatedCryptos;
        } catch (error) {
            console.error('Error fetching current prices:', error);
            return cryptos;
        }
    };

    const handleInputChange = (cryptoId, field, value) => {
        setCryptosState(prevState =>
            prevState.map(crypto =>
                crypto.id === cryptoId ? { ...crypto, [field]: value } : crypto
            )
        );
    };

    const deleteCryptoData = async (cryptoId) => {
        try {
            const response = await axios.delete(`/api/cryptos/${cryptoId}/`);
            if (response.status === 204) {
                setCryptosState(prevState => prevState.filter(crypto => crypto.id !== cryptoId));
            }
        } catch (error) {
            console.error('Failed to delete crypto:', error);
        }
    };

    const updateCryptoData = async (crypto) => {
        try {
            const response = await axios.put(`/api/cryptos/${crypto.id}/`, crypto);
            if (response.status === 200) {
                // Handle successful update logic here
            }
        } catch (error) {
            console.error('Failed to update crypto data:', error);
        }
    };

    const handleSaveNewCrypto = async () => {
        try {
            const response = await axios.post('/api/cryptos/', newCrypto);
            if (response && response.data) {
                setCryptosState(prev => [...prev, response.data]);
                setIsModalOpen(false);
            }
        } catch (error) {
            console.error('Failed to add crypto:', error);
        }
    };

    const calculateTotals = () => {
        let totalInvestment = 0;
        let totalCurrent = 0;

        cryptosState.forEach(crypto => {
            totalInvestment += parseFloat(crypto.cryptos_buymarket);
            totalCurrent += (parseFloat(crypto.current_price) * parseFloat(crypto.cryptos_quantity));
        });

        const ratereinvestment = totalCurrent - totalInvestment;
        const ratecapital = totalCurrent - totalCapital;
        const percentreinvestment = totalInvestment ? ((ratereinvestment / totalInvestment) * 100).toFixed(2) : 0;
        const percentcapital = totalCapital ? ((ratecapital / totalCapital) * 100).toFixed(2) : 0;

        return {
            totalInvestment: totalInvestment.toFixed(2),
            totalCurrent: totalCurrent.toFixed(2),
            ratereinvestment: ratereinvestment.toFixed(2),
            ratecapital: ratecapital.toFixed(2),
            percentreinvestment: percentreinvestment,
            percentcapital: percentcapital
        };
    };

    const formatNumber = (number) => {
        return number.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    };

    const totals = calculateTotals();

    if (loading) {
        return (
            <div className='flex items-center justify-center w-full'>
                <div className='items-center justify-center rounded-sm'>
                    <GridLoader color={"#123abc"} />
                </div>
            </div>
        );
    }

    if (error) {
        return <p className="text-center text-red-500">Error loading data: {error}</p>;
    }

    return (
        <div className="mt-5">
            {/* Summary Table */}
            <div className="relative mb-5 overflow-x-auto">
                <table className="w-full text-[10px] md:text-sm text-left text-blue-100">
                    <thead className="text-[8px] md:text-xs text-white uppercase bg-blue-500">
                        <tr>
                            <th className="px-2 md:px-6 py-2 md:py-3">Total VND</th>
                            <th className="px-2 md:px-6 py-2 md:py-3">Total Capital</th>
                            <th className="px-2 md:px-6 py-2 md:py-3">Total Reinvest</th>
                            <th className="px-2 md:px-6 py-2 md:py-3">Total Invest</th>
                            <th className="px-2 md:px-6 py-2 md:py-3">Total Current</th>
                            <th className="px-2 md:px-6 py-2 md:py-3">Profit/Reinvest</th>
                            <th className="px-2 md:px-6 py-2 md:py-3">% Reinvest</th>
                            <th className="px-2 md:px-6 py-2 md:py-3">Profit/Capital</th>
                            <th className="px-2 md:px-6 py-2 md:py-3">% Capital</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-gray-50">
                            <td className="px-2 md:px-6 py-2 md:py-4 font-semibold text-black border border-gray-300">{formatNumber(totalVND)} ₫</td>
                            <td className="px-2 md:px-6 py-2 md:py-4 font-semibold text-black border border-gray-300">${formatNumber(totalCapital)}</td>
                            <td className="px-2 md:px-6 py-2 md:py-4 font-semibold text-black border border-gray-300">${formatNumber(totals.totalInvestment - totalCapital)}</td>
                            <td className="px-2 md:px-6 py-2 md:py-4 font-semibold text-black border border-gray-300">${formatNumber(totals.totalInvestment)}</td>
                            <td className="px-2 md:px-6 py-2 md:py-4 font-semibold text-black border border-gray-300">${formatNumber(totals.totalCurrent)}</td>
                            <td className={`px-2 md:px-6 py-2 md:py-4 text-black border border-gray-300 font-semibold ${totals.ratereinvestment > 0 ? 'bg-green-200' : totals.ratereinvestment < 0 ? 'bg-red-100' : ''}`}>{totals.ratereinvestment !== '0.00' ? `$${formatNumber(totals.ratereinvestment)}` : ''}</td>
                            <td className={`px-2 md:px-6 py-2 md:py-4 border text-black border-gray-300 font-semibold ${totals.percentreinvestment > 0 ? 'bg-green-200' : totals.percentreinvestment < 0 ? 'bg-red-600' : ''}`}>{totals.percentreinvestment !== '0.00' ? `${totals.percentreinvestment}%` : ''}</td>
                            <td className={`px-2 md:px-6 py-2 md:py-4 text-black border border-gray-300 font-semibold ${totals.ratecapital > 0 ? 'bg-green-600' : totals.ratecapital < 0 ? 'bg-red-100' : ''}`}>{totals.ratecapital !== '0.00' ? `$${formatNumber(totals.ratecapital)}` : ''}</td>
                            <td className={`px-2 md:px-6 py-2 md:py-4 border text-black border-gray-300 font-semibold ${totals.percentcapital > 0 ? 'bg-green-600' : totals.percentcapital < 0 ? 'bg-red-300' : ''}`}>{totals.percentcapital !== '0.00' ? `${totals.percentcapital}%` : ''}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Main Table */}
            <div className="container overflow-x-auto">
                <table className="w-full text-[10px] md:text-sm text-left text-black">
                    <thead className="text-[8px] md:text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th className="sticky left-0 p-1 px-2 md:px-6 py-2 md:py-4 border bg-gray-50">Name</th>
                            <th className="p-1 px-2 md:px-6 py-2 md:py-4 border">Quantity</th>
                            <th className="p-1 px-2 md:px-6 py-2 md:py-4 border">Buy Market</th>
                            <th className="p-1 px-2 md:px-6 py-2 md:py-4 border">Current Wallet</th>
                            <th className="p-1 px-2 md:px-6 py-2 md:py-4 border">USD Buy</th>
                            <th className="p-1 px-2 md:px-6 py-2 md:py-4 border">USD Sell</th>
                            <th className="p-1 px-2 md:px-6 py-2 md:py-4 border">Avg Price</th>
                            <th className="p-1 px-2 md:px-6 py-2 md:py-4 border">Current Price</th>
                            <th className="p-1 px-2 md:px-6 py-2 md:py-4 border">Rate</th>
                            <th className="p-1 px-2 md:px-6 py-2 md:py-4 border">%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cryptosState.map(crypto => {
                            const averagingPrice = crypto.cryptos_quantity ? (crypto.cryptos_buymarket / crypto.cryptos_quantity).toFixed(6) : 0;
                            const rate = ((crypto.current_price * crypto.cryptos_quantity) - crypto.cryptos_buymarket).toFixed(2);
                            const percent = crypto.cryptos_buymarket ? (((crypto.current_price * crypto.cryptos_quantity) - crypto.cryptos_buymarket) / crypto.cryptos_buymarket * 100).toFixed(2) : 0;
                            const current_wallet = (crypto.current_price * crypto.cryptos_quantity).toFixed(2);
                            return (
                                <tr key={crypto.id} className="text-[10px] md:text-xs border-b odd:bg-white even:bg-gray-50">
                                    <td className="sticky left-0 p-1 px-2 md:px-6 py-2 md:py-4 font-semibold border">{crypto.cryptos_name}</td>
                                    <td className="p-1 px-2 md:px-6 py-2 md:py-4 border">{formatNumber(crypto.cryptos_quantity)}</td>
                                    <td className="p-1 px-2 md:px-6 py-2 md:py-4 border">${formatNumber(crypto.cryptos_buymarket)}</td>
                                    <td className="p-1 px-2 md:px-6 py-2 md:py-4 border">${formatNumber(current_wallet)}</td>
                                    <td className="p-1 px-2 md:px-6 py-2 md:py-4 border">${formatNumber(crypto.cryptos_usdbuy)}</td>
                                    <td className="p-1 px-2 md:px-6 py-2 md:py-4 border">${formatNumber(crypto.cryptos_usdsell)}</td>
                                    <td className="p-1 px-2 md:px-6 py-2 md:py-4 border">${formatNumber(averagingPrice)}</td>
                                    <td className="p-1 px-2 md:px-6 py-2 md:py-4 border">${formatNumber(crypto.current_price || 0)}</td>
                                    <td className={`p-1 px-2 md:px-6 py-2 md:py-4 border text-black font-semibold ${rate > 0 ? 'bg-green-400' : rate < 0 ? 'bg-red-400' : ''}`}>{rate !== '0.00' ? `$${formatNumber(rate)}` : ''}</td>
                                    <td className={`p-1 px-2 md:px-6 py-2 md:py-4 border text-black font-semibold ${percent > 0 ? 'bg-green-400' : percent < 0 ? 'bg-red-400' : ''}`}>{percent !== '0.00' ? `${percent}%` : ''}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 z-10 flex items-center justify-center">
                    <div className="fixed inset-0 transition-opacity bg-gray-900 bg-opacity-75"></div>
                    <div className="w-full p-4 bg-white rounded-lg shadow-lg dark:bg-gray-700 md:max-w-md">
                        <div className="flex justify-center">
                            <div className="px-6 pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8">
                                <h3 className="text-xl font-medium text-gray-900 dark:text-white">Add Crypto</h3>
                                {['cryptos_name', 'cryptos_quantity', 'cryptos_buymarket', 'cryptos_usdbuy', 'cryptos_usdsell'].map((field, index) => (
                                    <div key={index} className="mb-4">
                                        <label className="block mb-2 text-sm font-bold text-gray-700">
                                            {field.replace('cryptos_', '').replace('_', ' ').toUpperCase()}
                                        </label>
                                        <input
                                            type="text"
                                            value={newCrypto[field]}
                                            onChange={(e) => setNewCrypto({ ...newCrypto, [field]: e.target.value })}
                                            className="p-1 border border-gray-300 rounded"
                                        />
                                    </div>
                                ))}
                                <button
                                    className="px-4 py-2 text-sm font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                                    onClick={handleSaveNewCrypto}
                                >
                                    Save
                                </button>
                                <button
                                    className="px-4 py-2 ml-2 text-sm font-bold text-gray-700 bg-gray-300 rounded"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}


export default CryptosTable;
