/**
 * SitesTableData Component
 * Component hiển thị bảng dữ liệu thống kê theo từng site
 * Bao gồm thông tin về network và tổng số coins
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { BarLoader } from 'react-spinners';

// Các hằng số cho style
const TABLE_CONTAINER_STYLE = 'bg-white rounded-sm my-4';
const TABLE_HEADER_STYLE = 'border-b p-1 border-gray-200 p-3 text-sm text-center uppercase font-semibold';
const TABLE_STYLE = 'w-full divide-y divide-gray-200';
const TABLE_HEAD_BG_STYLE = 'bg-gray-50';
const TABLE_HEAD_CELL_STYLE = 'px-3 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider';
const TABLE_CELL_STYLE = 'px-6 py-2 whitespace-nowrap text-sm text-center';
const TABLE_CELL_FONT_STYLE = 'font-medium text-gray-900';
const TABLE_CELL_VALUE_STYLE = 'text-gray-500';

// Các hằng số cho loading state
const LOADING_CONTAINER_STYLE = 'flex items-center justify-center  h-[400px] bg-white rounded-lg';
const ERROR_CONTAINER_STYLE = 'flex items-center justify-center  h-[400px] bg-white rounded-lg text-red-500';
const NO_DATA_CONTAINER_STYLE = 'flex items-center justify-center  h-[400px] bg-white rounded-lg text-gray-500';

// Component hiển thị loading
const LoadingIndicator = () => (
    <div className={LOADING_CONTAINER_STYLE}>
        <BarLoader color="#3592e8" />
    </div>
);

// Component hiển thị lỗi
const ErrorIndicator = ({ message }) => (
    <div className={ERROR_CONTAINER_STYLE}>
        <p>Error loading table data: {message}</p>
    </div>
);

// Component hiển thị không có dữ liệu
const NoDataIndicator = () => (
    <div className={NO_DATA_CONTAINER_STYLE}>
        <p>No table data available</p>
    </div>
);

/**
 * Trích xuất phần giữa của domain
 * @param {string} domain - Tên miền đầy đủ
 * @returns {string} Phần giữa của tên miền
 */
const extractMiddleDomain = (domain) => {
    const parts = domain.split('.');
    return parts.length === 3 ? parts[1] : domain;
};

const SitesTableData = () => {
    // Lấy dữ liệu và trạng thái từ Redux store
    const sitesTableData = useSelector(state => state.table.sitesTableData);
    const loading = useSelector(state => state.table.loading.sitesTable);
    const error = useSelector(state => state.table.error.sitesTable);

    // Hiển thị loading state
    if (loading) {
        return <LoadingIndicator />;
    }

    // Hiển thị error state
    if (error) {
        return <ErrorIndicator message={error} />;
    }

    // Hiển thị no data state
    if (!sitesTableData || sitesTableData.length === 0) {
        return <NoDataIndicator />;
    }

    return (
        <>
            {sitesTableData.map((siteData, siteIndex) => (
                <div key={siteIndex} className={TABLE_CONTAINER_STYLE}>
                    {/* Header của bảng */}
                    <div className={TABLE_HEADER_STYLE}>
                        {extractMiddleDomain(siteData.site)}
                    </div>

                    {/* Bảng dữ liệu */}
                    <table className={TABLE_STYLE}>
                        <thead className={TABLE_HEAD_BG_STYLE}>
                            <tr>
                                <th scope='col' className={TABLE_HEAD_CELL_STYLE}>
                                    Network
                                </th>
                                <th scope='col' className={TABLE_HEAD_CELL_STYLE}>
                                    Total Coins
                                </th>
                            </tr>
                        </thead>
                        <tbody className='bg-white divide-y divide-gray-200'>
                            {siteData.networks.map((network, networkIndex) => (
                                <tr key={networkIndex}>
                                    <td className={`${TABLE_CELL_STYLE} ${TABLE_CELL_FONT_STYLE}`}>
                                        {network.network}
                                    </td>
                                    <td className={`${TABLE_CELL_STYLE} ${TABLE_CELL_VALUE_STYLE}`}>
                                        ${network.total_coins}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}
        </>
    );
};

export default SitesTableData;
