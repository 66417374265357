/**
 * SitesTrendChart Component
 * Component hiển thị biểu đồ thống kê doanh thu theo từng site
 * Sử dụng thư viện ApexCharts để vẽ biểu đồ cột xếp chồng
 */

import React from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { BarLoader } from 'react-spinners';
import config from '../../../config';

// Các hằng số cho style
const CHART_CONTAINER_STYLE = 'bg-white rounded-sm';
const CHART_HEADER_STYLE = 'border-b p-1 border-gray-200 p-3 text-sm font-semibold';
const CHART_TITLE = 'Thống Kê Site Theo Từng Ngày';
const LOADING_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg';
const ERROR_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg text-red-500';
const NO_DATA_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg text-gray-500';

// Component hiển thị loading
const LoadingIndicator = () => (
    <div className={LOADING_CONTAINER_STYLE}>
        <BarLoader color="#3592e8" />
    </div>
);

// Component hiển thị lỗi
const ErrorIndicator = ({ message }) => (
    <div className={ERROR_CONTAINER_STYLE}>
        <p>Error loading chart data: {message}</p>
    </div>
);

// Component hiển thị không có dữ liệu
const NoDataIndicator = () => (
    <div className={NO_DATA_CONTAINER_STYLE}>
        <p>No chart data available</p>
    </div>
);

/**
 * Chuẩn hóa dữ liệu cho biểu đồ
 * @param {Array} data - Dữ liệu thô từ Redux store
 * @returns {Array} Dữ liệu đã được chuẩn hóa
 */
const normalizeData = (data) => {
    // Lấy tất cả các ngày duy nhất từ dữ liệu
    const allDates = [...new Set(data.flatMap(site => site.data.map(entry => entry.x)))];
    allDates.sort((a, b) => new Date(a) - new Date(b));

    // Chuẩn hóa dữ liệu cho từng site
    return data.map(site => ({
        name: site.name,
        data: allDates.map(date => {
            const entry = site.data.find(d => d.x === date);
            return entry ? entry.y : 0;
        }),
    }));
};

/**
 * Trích xuất phần giữa của tên site
 * @param {string} siteName - Tên site đầy đủ
 * @returns {string} Phần giữa của tên site
 */
const extractMiddlePart = (siteName) => {
    const parts = siteName.split('.');
    return parts.length === 3 ? parts[1] : siteName;
};

/**
 * Cấu hình cho biểu đồ
 * @param {Array} categories - Danh sách các ngày cho trục x
 * @returns {Object} Cấu hình cho biểu đồ
 */
const getChartOptions = (categories) => ({
    chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
            show: false,
        },
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '45%',
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        width: 1,
        colors: ['#fff']
    },
    xaxis: {
        type: 'category',
        categories,
    },
    yaxis: {
        title: {
            text: 'Doanh Thu ($)'
        },
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: (val) => `${val} $`
        }
    },
    legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetY: 5
    }
});

const SitesTrendChart = () => {
    // Lấy dữ liệu từ Redux store
    const rawData = useSelector(state => state.charts.sitesTrendChartData);
    const loading = useSelector(state => state.charts.loading.sitesTrendChart);
    const error = useSelector(state => state.charts.error.sitesTrendChart);

    // Hiển thị loading state
    if (loading) {
        return <LoadingIndicator />;
    }

    // Hiển thị error state
    if (error) {
        return <ErrorIndicator message={error} />;
    }

    // Hiển thị no data state
    if (!rawData || rawData.length === 0) {
        return <NoDataIndicator />;
    }

    // Chuẩn hóa dữ liệu
    const chartData = normalizeData(rawData);

    // Tạo dữ liệu series cho biểu đồ
    const seriesData = chartData.map(site => ({
        name: extractMiddlePart(site.name),
        data: site.data,
    }));

    // Lấy danh sách ngày cho trục x
    const allDates = [...new Set(rawData.flatMap(site => site.data.map(entry => entry.x)))];
    allDates.sort((a, b) => new Date(a) - new Date(b));

    // Tạo cấu hình biểu đồ
    const chartOptions = getChartOptions(allDates);

    return (
        <div className={CHART_CONTAINER_STYLE}>
            <div className={CHART_HEADER_STYLE}>
                {CHART_TITLE}
            </div>
            <ReactApexChart
                className="bg-white rounded-lg"
                options={chartOptions}
                series={seriesData}
                type="bar"
                height={350}
            />
        </div>
    );
};

export default SitesTrendChart;
