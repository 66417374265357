import { Link, useLocation } from 'react-router-dom';
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function Breadcrumb() {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    const breadcrumbItems = [
        { text: 'Dashboard', path: '/' },
        ...pathnames.map((pathname, index) => ({
            text: capitalizeFirstLetter(pathname),
            path: `/${pathname}`,
        })),
    ];

    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 breadcrumb">
                {breadcrumbItems.map((item, index) => (
                    <li key={index} aria-current={index === breadcrumbItems.length - 1 ? 'page' : undefined}>
                        <div className="flex items-center breadcrumb-items">
                            {index !== 0 && (
                                <svg
                                    className="w-3 h-3 mx-1 text-gray-400 rtl:rotate-180"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 6 10"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 9 4-4-4-4"
                                    />
                                </svg>
                            )}
                            {index === breadcrumbItems.length - 1 ? (
                                <span
                                    className={`inline-flex text-sm items-center ${index === breadcrumbItems.length - 1 ? 'text-gray-500' : 'text-gray-700'
                                        } md:ms-2 dark:text-gray-400`}
                                >
                                    {item.text}
                                </span>
                            ) : (
                                <Link
                                    to={item.path}
                                    className={`inline-flex text-sm font-semibold items-center ${index === breadcrumbItems.length - 1 ? 'text-gray-500' : 'text-gray-700'
                                        } md:ms-2 dark:text-gray-400`}
                                >
                                    {item.text}
                                </Link>
                            )}
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    );
}

export default Breadcrumb;