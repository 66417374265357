/**
 * Overview Component
 * Component chính hiển thị tổng quan về hiệu suất và thống kê của hệ thống
 * Bao gồm các phần:
 * 1. Thống kê tổng quan (Statistics)
 * 2. Xếp hạng hàng ngày (Daily Ranking)
 * 3. Chấm công (Attendance)
 * 4. Phân tích xu hướng (Analytics)
 * 5. Hiệu suất (Performance)
 * 6. Hiệu suất theo site (Site Performance)
 */

import NetworkTrendChart from "./overview/networktrendchart";
import SitesTableData from "./overview/sitestabledata";
import SitesTrendChart from "./overview/sitestrendchart";
import Statistics from "./overview/statistics";
import TopOfferTableData from "./overview/topoffertabledata";
import WorkTrendChart from "./overview/worktrendchart";
import DailyRankChart from "./overview/dailyrankchart";
import AttendanceTable from "./overview/attendance";

function Overview() {
    return (
        <div className="mt-20">
            {/* Phần thống kê tổng quan */}
            <section className="statistics">
                <div className="pl-5">
                    <h2 className="font-semibold">
                        Statistics
                    </h2>
                    <p className="text-xs">
                        Tóm tắt đánh giá kết quả chung của công việc
                    </p>
                </div>
                <div className="mb-5">
                    <Statistics />
                </div>
            </section>

            {/* Phần xếp hạng hàng ngày */}
            <section className="statistics">
                <div className="pl-5">
                    <h2 className="font-semibold">
                        Daily Ranking
                    </h2>
                    <p className="text-xs">
                        Kết quả từng nhân viên trong ngày
                    </p>
                </div>
                <div className="mb-5">
                    <DailyRankChart />
                </div>
            </section>

            {/* Phần chấm công */}
            <section className="statistics">
                <div className="pl-5">
                    <h2 className="font-semibold">
                        Attendance
                    </h2>
                    <p className="text-xs">
                        Chú ý chấm công hằng ngày
                    </p>
                </div>
                <div className="mb-5">
                    <AttendanceTable />
                </div>
            </section>

            {/* Phần phân tích xu hướng */}
            <section className="charttrend">
                <div className="pl-5">
                    <h2 className="font-semibold">
                        Analytics
                    </h2>
                    <p className="text-xs">
                        Thống kê kết quả chung của công việc
                    </p>
                </div>
                <div className="grid p-1 mb-6 charttrend gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
                    <WorkTrendChart />
                    <SitesTrendChart />
                </div>
            </section>

            {/* Phần hiệu suất */}
            <section className="charttrend">
                <div className="pl-5">
                    <h2 className="font-semibold">
                        Performance
                    </h2>
                    <p className="text-xs">
                        Tóm tắt hiệu suất của công việc
                    </p>
                </div>
                <div className="grid p-1 mb-6 charttrend gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
                    <NetworkTrendChart />
                    <TopOfferTableData />
                </div>
            </section>

            {/* Phần hiệu suất theo site */}
            <section className="site-analytics">
                <div className="pl-5">
                    <h2 className="font-semibold">
                        Site Performance
                    </h2>
                    <p className="text-xs">
                        Tóm tắt hiệu suất của từng site
                    </p>
                </div>
                <div className="grid p-1 mb-6 sitesdata gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-5">
                    <SitesTableData />
                </div>
            </section>
        </div>
    )
}

export default Overview;