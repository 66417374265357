import config from '../../config';

function Footer(){
    return(
        <div className="footer">
            <footer className="bg-white rounded-lg shadow m-4 dark:bg-gray-800">
                <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                        © {config.UI.COPYRIGHT_YEAR} <a href={config.DASHBOARD_URL} className="hover:underline">
                            {config.SITES.MAIN.NAME}{config.UI.TRADEMARK}
                        </a>. {config.UI.COPYRIGHT_TEXT}.
                    </span>
                    <ul className="flex flex-wrap items-end mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                        <li>
                            <span className="me-4 md:me-6">Crafted by <a href={config.CONTACT.DEVELOPER.FACEBOOK}>{config.CONTACT.DEVELOPER.NAME}</a></span>
                        </li>
                        <li>
                            <span className="me-4 md:me-6">Contact: {config.CONTACT.DEVELOPER.PHONE}</span>
                        </li>
                    </ul>
                </div>
            </footer>
        </div>
    )
}
export default Footer;