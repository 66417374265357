import React, { useState } from 'react';
import IproxyControl from "./iproxy";
import FastProxyControl from "./fastproxy";
import Statistics from "../overview/statistics";

function Proxy() {
    // Sử dụng state để theo dõi thứ tự của các component
    const [order, setOrder] = useState(['IproxyControl', 'FastProxyControl']);

    // Hàm để tráo đổi vị trí của IproxyControl và FastProxyControl
    const swapComponents = () => {
        setOrder(prevOrder => prevOrder[0] === 'IproxyControl' ? ['FastProxyControl', 'IproxyControl'] : ['IproxyControl', 'FastProxyControl']);
    };

    return (
        <div className="mt-16">
            <div className="charttrend">
                <div className='mt-5'>
                    <Statistics />
                </div>
                <div className="flex items-center justify-between p-2 m-2 rounded-lg shadow-lg bg-gradient-to-r from-blue-300 to-blue-700">
                    <div className="flex items-center gap-3">
                        <div className="p-2 rounded-lg bg-white/10">
                            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                            </svg>
                        </div>
                        <div>
                            <h2 className="text-xl font-bold text-white">Proxy Management</h2>
                            <p className="text-sm text-blue-100">Manage and control your proxy servers</p>
                        </div>
                    </div>
                    <button 
                        onClick={swapComponents} 
                        className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-blue-600 transition-all duration-200 bg-white rounded-lg group hover:bg-blue-50 focus:ring-4 focus:outline-none focus:ring-blue-300"
                    >
                        <svg className="w-5 h-5 transition-transform duration-200 group-hover:rotate-180" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                        </svg>
                        Swap Controls
                    </button>
                </div>
                <div className="grid grid-cols-1 gap-6 p-4 lg:grid-cols-2">
                    {order.map(component => (
                        <div key={component} className="overflow-hidden bg-white rounded-lg shadow-md">
                            {component === 'IproxyControl' ? (
                                <IproxyControl />
                            ) : (
                                <FastProxyControl />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Proxy;
