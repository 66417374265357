/**
 * TopOfferTableData Component
 * Component hiển thị danh sách các offer hàng đầu với chức năng phân trang và thêm mới
 */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import axios from '../../../services/axios';
import { BarLoader } from 'react-spinners';

// Các hằng số
const ITEMS_PER_PAGE = 10;
const DEFAULT_IMAGE = "https://easyappicon.com/image/adaptive-icon.svg";

// Các hằng số cho style
const CONTAINER_STYLE = 'max-w-full bg-white rounded-sm';
const HEADER_STYLE = 'flex justify-between p-1 p-3 text-sm font-semibold border-b border-gray-200';
const ADD_BUTTON_STYLE = 'px-2 text-white bg-blue-500 rounded hover:bg-blue-700 text-exs';
const MODAL_OVERLAY_STYLE = 'fixed inset-0 z-10 overflow-y-auto';
const MODAL_BACKDROP_STYLE = 'fixed inset-0 transition-opacity bg-gray-900 bg-opacity-75';
const MODAL_CONTENT_STYLE = 'w-full p-4 bg-white rounded-lg shadow-lg dark:bg-gray-700 md:max-w-md';
const INPUT_STYLE = 'mb-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white';
const SAVE_BUTTON_STYLE = 'w-full mt-10 mb-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800';
const CANCEL_BUTTON_STYLE = 'w-full bg-gray-300 text-gray-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800';

// Thêm các hằng số cho loading state
const LOADING_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg';
const ERROR_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg text-red-500';
const NO_DATA_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg text-gray-500';

// Component hiển thị loading
const LoadingIndicator = () => (
    <div className={LOADING_CONTAINER_STYLE}>
        <BarLoader color="#3592e8" />
    </div>
);

// Component hiển thị lỗi
const ErrorIndicator = ({ message }) => (
    <div className={ERROR_CONTAINER_STYLE}>
        <p>Error loading table data: {message}</p>
    </div>
);

// Component hiển thị không có dữ liệu
const NoDataIndicator = () => (
    <div className={NO_DATA_CONTAINER_STYLE}>
        <p>No table data available</p>
    </div>
);

const TopOfferTableData = () => {
    // State management
    const topOfferTableData = useSelector(state => state.table.topOfferTableData);
    const loading = useSelector(state => state.table.loading.topOfferTable);
    const error = useSelector(state => state.table.error.topOfferTable);
    const [currentPage, setCurrentPage] = useState(0);
    const [paginatedData, setPaginatedData] = useState([]);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [newOffer, setNewOffer] = useState({
        offer_imagelink: '',
        offer_tag: ''
    });

    /**
     * Mở modal thêm mới offer
     */
    const openAddModal = () => {
        setNewOffer({
            offer_imagelink: '',
            offer_tag: ''
        });
        setAddModalOpen(true);
    };

    /**
     * Lưu offer mới
     */
    const saveNewOffer = () => {
        axios.post('/api/offer-images/', newOffer)
            .then(() => {
                setAddModalOpen(false);
            })
            .catch((error) => {
                console.error('Error adding new offer:', error);
            });
    };

    /**
     * Cập nhật dữ liệu phân trang khi thay đổi trang hoặc dữ liệu
     */
    useEffect(() => {
        if (topOfferTableData.length > 0) {
            const startIndex = currentPage * ITEMS_PER_PAGE;
            const endIndex = startIndex + ITEMS_PER_PAGE;
            setPaginatedData(topOfferTableData.slice(startIndex, endIndex));
        }
    }, [currentPage, topOfferTableData]);

    /**
     * Xử lý khi thay đổi trang
     */
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Hiển thị loading state
    if (loading) {
        return <LoadingIndicator />;
    }

    // Hiển thị error state
    if (error) {
        return <ErrorIndicator message={error} />;
    }

    // Hiển thị no data state
    if (!topOfferTableData || topOfferTableData.length === 0) {
        return <NoDataIndicator />;
    }

    return (
        <div className={CONTAINER_STYLE}>
            <div className={HEADER_STYLE}>
                <span>Top Offer</span>
                <button className={ADD_BUTTON_STYLE} onClick={openAddModal}>+ Add App</button>
                
                {/* Modal thêm mới offer */}
                {isAddModalOpen && (
                    <div className={MODAL_OVERLAY_STYLE}>
                        <div className="flex items-end justify-center min-h-screen">
                            <div className="fixed inset-0 z-10 overflow-y-auto" aria-labelledby="modal title" role="dialog" aria-modal="true">
                                <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                                    <div className={MODAL_BACKDROP_STYLE} aria-hidden="true"></div>
                                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                                    <div className="fixed inset-0 z-50 flex items-center justify-center">
                                        <div className={MODAL_CONTENT_STYLE}>
                                            <form className="px-6 pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8">
                                                <h3 className="text-xl font-medium text-gray-900 dark:text-white">Add New Offer</h3>
                                                <div>
                                                    <div>
                                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Image App Link</label>
                                                        <input
                                                            type="text"
                                                            value={newOffer.offer_imagelink}
                                                            onChange={(e) => setNewOffer({ ...newOffer, offer_imagelink: e.target.value })}
                                                            className={INPUT_STYLE}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Offers Tag</label>
                                                        <input
                                                            type="text"
                                                            value={newOffer.offer_tag}
                                                            onChange={(e) => setNewOffer({ ...newOffer, offer_tag: e.target.value })}
                                                            className={INPUT_STYLE}
                                                            required
                                                        />
                                                    </div>
                                                    <button type="button" onClick={saveNewOffer} className={SAVE_BUTTON_STYLE}>Save Offer</button>
                                                    <button type="button" onClick={() => setAddModalOpen(false)} className={CANCEL_BUTTON_STYLE}>Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Danh sách offers */}
            <div className="p-2 bg-white rounded-lg overflow-x-none">
                <div className="divide-y divide-gray-200">
                    {paginatedData.map((item, index) => (
                        <li key={index} className="flex items-center justify-between w-full py-2">
                            {/* Thông tin offer bên trái */}
                            <div className="flex items-center w-2/5">
                                <div className="flex items-center justify-center icon min-w-[32px] sm:min-w-[48px]">
                                    <img
                                        src={item.offer_image_link || DEFAULT_IMAGE}
                                        alt="Offer Image"
                                        className="w-8 h-8 rounded-md sm:w-12 sm:h-12"
                                    />
                                </div>
                                <div className="ml-2 overflow-hidden">
                                    <p className="text-xs font-semibold text-gray-900 truncate">
                                        {item.history_offername}
                                    </p>
                                    <p className="text-xs text-gray-500 truncate">{item.offer_coins} Coins</p>
                                </div>
                            </div>

                            {/* Thông tin chi tiết bên phải */}
                            <div className="w-3/5 pl-2">
                                <div className="grid w-full grid-cols-2 gap-1 sm:flex sm:flex-row sm:gap-2 sm:justify-end">
                                    <div className="flex items-center justify-center min-w-[45px] sm:min-w-[100px]">
                                        <span className="w-full px-1 py-0.5 sm:px-2 sm:py-1 text-[10px] sm:text-xs text-center text-gray-900 rounded bg-emerald-300 truncate">{item.history_network}</span>
                                    </div>
                                    <div className="flex items-center justify-center min-w-[45px] sm:min-w-[100px]">
                                        <span className="w-full px-1 py-0.5 sm:px-2 sm:py-1 text-[10px] sm:text-xs text-center text-gray-900 bg-blue-300 rounded truncate">${item.total_coins}</span>
                                    </div>
                                    <div className="flex items-center justify-center min-w-[45px] sm:min-w-[100px]">
                                        <span className="w-full px-1 py-0.5 sm:px-2 sm:py-1 text-[10px] sm:text-xs text-center text-gray-900 bg-orange-300 rounded truncate">{item.lead}</span>
                                    </div>
                                    <div className="flex items-center justify-center min-w-[45px] sm:min-w-[100px]">
                                        <span className="w-full px-1 py-0.5 sm:px-2 sm:py-1 flex justify-center items-center gap-1 bg-blue-100 text-blue-800 text-[10px] sm:text-xs font-medium rounded dark:bg-blue-900 dark:text-blue-300 truncate">
                                            {item.offer_geo}
                                            <img
                                                src={`https://flagsapi.com/${item.offer_geo.toUpperCase()}/shiny/16.png`}
                                                alt={`Flag of ${item.offer_geo}`}
                                                className="w-3 h-3 ml-1 sm:w-4 sm:h-4"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </div>

                {/* Phân trang */}
                <ReactPaginate
                    pageCount={Math.ceil(topOfferTableData.length / ITEMS_PER_PAGE)}
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    );
};

export default TopOfferTableData;
