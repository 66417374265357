// Inside your managementSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios'; // Ensure this path is correct

// Initial state for the management slice
const initialState = {
  sitesManagerData: [], // Data for site management
  loading: {
    sitesManager: false, // Loading state for site management
  },
  error: {
    sitesManager: null, // Error state for site management
  },
};

// Async thunk for fetching Sites Manager Data
export const fetchSitesManagerData = createAsyncThunk(
  'management/fetchSitesManagerData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/sites-managerment/');
      return response; // Assuming the API returns an array of site management data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Define custom actions to delete and update a site
export const deleteSite = createAsyncThunk(
  'management/deleteSite',
  async (siteId, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/sites-managerment/${siteId}`);
      return siteId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSite = createAsyncThunk(
  'management/updateSite',
  async (updatedSite, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/sites-managerment/`, updatedSite);
      return response.data; // Assuming the updated site data is returned by the API
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice definition for management
const managementSlice = createSlice({
  name: 'management',
  initialState,
  reducers: {
    // Add reducers for other management actions here (e.g., addSite, editSite)
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSitesManagerData.pending, (state) => {
        state.loading.sitesManager = true;
        state.error.sitesManager = null;
      })
      .addCase(fetchSitesManagerData.fulfilled, (state, action) => {
        state.sitesManagerData = action.payload;
        state.loading.sitesManager = false;
      })
      .addCase(fetchSitesManagerData.rejected, (state, action) => {
        state.error.sitesManager = action.payload;
        state.loading.sitesManager = false;
      })
      .addCase(deleteSite.fulfilled, (state, action) => {
        // Remove the deleted site from the state
        state.sitesManagerData = state.sitesManagerData.filter(
          (site) => site.id !== action.payload
        );
      })
      .addCase(updateSite.fulfilled, (state, action) => {
        // Update the site data in the state
        state.sitesManagerData = state.sitesManagerData.map((site) =>
          site.id === action.payload.id ? action.payload : site
          
        );
      });
    // Handle additional actions if necessary
  },
});

export const { /* export any reducers here */ } = managementSlice.actions;
export default managementSlice.reducer;
