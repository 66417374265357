import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios'; // Ensure this path is correct
import moment from 'moment-timezone';
import config from '../config';

const initialState = {
  usersAnalyticChartData: [],
  sitesAnalyticChartData: [],
  usersAnalyticsTableData: [], // New state for table data
  loading: {
    usersAnalyticChart: false,
    sitesAnalyticChart: false,
    usersAnalyticsTable: false, // Loading state for new table data
  },
  error: {
    usersAnalyticChart: null,
    sitesAnalyticChart: null,
    usersAnalyticsTable: null, // Error state for new table data
  },
};

// Async thunk for fetching UsersAnalyticChartData
export const fetchUsersAnalyticChartData = createAsyncThunk(
  'analytics/fetchUsersAnalyticChartData',
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(`/api/v1/useranalyticschart-data/?start_date=${startDate}&end_date=${endOfDay}`);
      return response; // Assuming the API returns an array of data for the chart
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Async thunk for fetching SitesAnalyticChartData
export const fetchSitesAnalyticChartData = createAsyncThunk(
  'analytics/fetchSitesAnalyticChartData',
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(`/api/v1/sitesanalyticschart-data/?start_date=${startDate}&end_date=${endOfDay}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// New async thunk for fetching UsersAnalyticsTableData
export const fetchUsersAnalyticsTableData = createAsyncThunk(
  'analytics/fetchUsersAnalyticsTableData',
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const response = await axios.get(`/api/v1/useranalyticstable-data/?start_date=${startDate}&end_date=${endOfDay}`);
      return response; // Assuming the API returns an array of data for the table
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Selector for UsersAnalyticChartData
export const selectUsersAnalyticChartData = (state) => state.analytics.usersAnalyticChartData;

// Selector for SitesAnalyticChartData
export const selectSitesAnalyticChartData = (state) => state.analytics.sitesAnalyticChartData;

// New selector for UsersAnalyticsTableData
export const selectUsersAnalyticsTableData = (state) => state.analytics.usersAnalyticsTableData;

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    // Regular reducers can be added here if needed
  },
  extraReducers: (builder) => {
    builder
      // Handling states for UsersAnalyticChartData
      .addCase(fetchUsersAnalyticChartData.pending, (state) => {
        state.loading.usersAnalyticChart = true;
      })
      .addCase(fetchUsersAnalyticChartData.fulfilled, (state, action) => {
        state.usersAnalyticChartData = action.payload;
        state.loading.usersAnalyticChart = false;
      })
      .addCase(fetchUsersAnalyticChartData.rejected, (state, action) => {
        state.error.usersAnalyticChart = action.payload;
        state.loading.usersAnalyticChart = false;
      })
      // Handling states for SitesAnalyticChartData
      .addCase(fetchSitesAnalyticChartData.pending, (state) => {
        state.loading.sitesAnalyticChart = true;
      })
      .addCase(fetchSitesAnalyticChartData.fulfilled, (state, action) => {
        state.sitesAnalyticChartData = action.payload;
        state.loading.sitesAnalyticChart = false;
      })
      .addCase(fetchSitesAnalyticChartData.rejected, (state, action) => {
        state.error.sitesAnalyticChart = action.payload;
        state.loading.sitesAnalyticChart = false;
      })
      // Handling states for UsersAnalyticsTableData
      .addCase(fetchUsersAnalyticsTableData.pending, (state) => {
        state.loading.usersAnalyticsTable = true;
      })
      .addCase(fetchUsersAnalyticsTableData.fulfilled, (state, action) => {
        state.usersAnalyticsTableData = action.payload;
        state.loading.usersAnalyticsTable = false;
      })
      .addCase(fetchUsersAnalyticsTableData.rejected, (state, action) => {
        state.error.usersAnalyticsTable = action.payload;
        state.loading.usersAnalyticsTable = false;
      });
  },
});

export default analyticsSlice.reducer;
