import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMemberSiteData } from '../../services/appsSlice';
import moment from 'moment';
import axios from '../../services/axios';

function MemberSiteManagement() {
    // Khởi tạo các hooks và state
    const dispatch = useDispatch();
    const { startDate, endDate } = useSelector((state) => state.dateRange);
    const memberSiteData = useSelector((state) => state.apps.membersiteData);
    
    // State quản lý phân trang và sắp xếp
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [sortingConfig, setSortingConfig] = useState({ 
        key: 'membersite_timecreated', 
        direction: 'descending' 
    });
    const [searchFilters, setSearchFilters] = useState({});
    
    // State quản lý modal xóa
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedMemberId, setSelectedMemberId] = useState(null);
    
    // Số lượng bản ghi hiển thị trên mỗi trang
    const RECORDS_PER_PAGE = 100;

    // Fetch dữ liệu khi thay đổi khoảng thời gian
    useEffect(() => {
        dispatch(fetchMemberSiteData({ startDate, endDate }));
    }, [dispatch, startDate, endDate]);

    // Xử lý dữ liệu đã được sắp xếp và lọc
    const processedMemberData = useMemo(() => {
        return memberSiteData
            .filter((item) =>
                Object.keys(searchFilters).every((key) =>
                    searchFilters[key] ? item[key]?.toLowerCase().includes(searchFilters[key].toLowerCase()) : true
                )
            )
            .sort((a, b) => {
                if (!sortingConfig.key) return 0;
                const sortOrder = sortingConfig.direction === 'ascending' ? 1 : -1;
                return (`${a[sortingConfig.key]}`.localeCompare(`${b[sortingConfig.key]}`, 'en', { numeric: true })) * sortOrder;
            });
    }, [memberSiteData, sortingConfig, searchFilters]);

    // Xử lý sắp xếp theo cột
    const handleColumnSort = (columnKey) => {
        setSortingConfig({
            key: columnKey,
            direction: sortingConfig.key === columnKey && sortingConfig.direction === 'ascending' ? 'descending' : 'ascending',
        });
    };

    // Xử lý chuyển trang
    const handlePageNavigation = (direction) => {
        setCurrentPageNumber((prevPage) => prevPage + direction);
    };

    // Xử lý thay đổi bộ lọc tìm kiếm
    const handleSearchFilterChange = (columnKey, searchValue) => {
        setSearchFilters({ ...searchFilters, [columnKey]: searchValue });
    };

    // Xử lý mở modal xóa
    const handleOpenDeleteModal = (memberId) => {
        setIsDeleteModalVisible(true);
        setSelectedMemberId(memberId);
    };

    // Xử lý xóa thành viên
    const handleDeleteMember = async () => {
        if (selectedMemberId) {
            try {
                await axios.delete(`api/v1/membersite/${selectedMemberId}/`);
                setIsDeleteModalVisible(false);
                dispatch(fetchMemberSiteData({ startDate, endDate }));
            } catch (error) {
                console.error('Error deleting member site:', error);
            }
        }
    };

    // Xử lý đóng modal xóa
    const handleCloseDeleteModal = () => {
        setIsDeleteModalVisible(false);
        setSelectedMemberId(null);
    };

    // Định nghĩa cấu trúc cột cho bảng
    const tableColumns = [
        { key: 'membersite_timecreated', name: 'Time Created', format: (value) => moment(value).format('DD/MM/YYYY HH:mm') },
        { key: 'membersite_username', name: 'Member Name' },
        { key: 'membersite_id', name: 'User ID' },
        { key: 'membersite_site', name: 'Site' },
        { key: 'membersite_coins', name: 'Coins' },
        { key: 'membersite_amount', name: 'Amount' },
        { key: 'membersite_ip', name: 'IP' },
        { key: 'membersite_geo', name: 'Geo' },
        { key: 'membersite_platform', name: 'Platform' },
    ];

    // Tính toán thông tin phân trang
    const totalMemberCount = processedMemberData.length;
    const startRecordIndex = (currentPageNumber - 1) * RECORDS_PER_PAGE + 1;
    const endRecordIndex = Math.min(startRecordIndex + RECORDS_PER_PAGE - 1, totalMemberCount);
    
    // Lấy dữ liệu cho trang hiện tại
    const currentPageData = useMemo(() => {
        const startIndex = (currentPageNumber - 1) * RECORDS_PER_PAGE;
        return processedMemberData.slice(startIndex, startIndex + RECORDS_PER_PAGE);
    }, [processedMemberData, currentPageNumber]);

    return (
        <div className="max-w-full mt-20 overflow-x-auto">
            <div className='items-center justify-center rounded-sm'>
                {/* Header của bảng */}
                <div className="pl-5 mb-10 ">
                    <div className="font-semibold">Danh Sách Trả Điểm</div>
                    <div className="text-xs">Thống kê kết quả chung của công việc</div>
                </div>

                {/* Điều khiển phân trang */}
                <div className="flex items-center justify-between m-4 text-sm">
                    <span className="text-xs">
                        Displaying {startRecordIndex}-{endRecordIndex} of {totalMemberCount} records
                    </span>
                    <div className="flex items-center space-x-2">
                        <button
                            onClick={() => handlePageNavigation(-1)}
                            disabled={currentPageNumber === 1}
                            className="px-4 py-1 text-sm font-medium text-white bg-blue-300 rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
                        >
                            Previous
                        </button>
                        <span className="px-4 py-1 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md">
                            {currentPageNumber}
                        </span>
                        <button
                            onClick={() => handlePageNavigation(1)}
                            disabled={currentPageNumber * RECORDS_PER_PAGE >= processedMemberData.length}
                            className="px-4 py-1 text-sm font-medium text-white bg-blue-300 rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
                        >
                            Next
                        </button>
                    </div>
                </div>

                {/* Bảng dữ liệu */}
                <table className="max-w-full text-gray-500 divide-y divide-gray-200 table-fixed">
                    <thead className="bg-gray-50">
                        {/* Header của bảng */}
                        <tr>
                            {tableColumns.map((column) => (
                                <th
                                    key={column.key}
                                    className="px-3 py-3 text-xs tracking-wider text-left text-gray-500 uppercase border border-gray-200 cursor-pointer whitespace-nowrap"
                                    onClick={() => handleColumnSort(column.key)}
                                >
                                    {column.name}
                                </th>
                            ))}
                            <th
                                key="action"
                                className="px-3 py-3 text-xs tracking-wider text-left text-gray-500 uppercase border border-gray-200 cursor-pointer whitespace-nowrap"
                            >
                                Action
                            </th>
                        </tr>
                        {/* Hàng bộ lọc tìm kiếm */}
                        <tr>
                            {tableColumns.map((column) => (
                                <th
                                    key={`filter-${column.key}`}
                                    className="px-1 py-1 border border-gray-200"
                                >
                                    <div className='flex items-center gap-1'>
                                        <svg width="11" height="11" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path d="m9.14375,1.83333l-4.39688,0a1.35625,1.42083 0 0 0 -1.24688,-0.91667a1.3125,1.375 0 0 0 -1.225,0.91667l-0.91875,0a0.45938,0.48125 0 0 0 -0.48125,0.45833a0.45938,0.48125 0 0 0 0.48125,0.45833l0.91875,0a1.3125,1.375 0 0 0 1.225,0.91667a1.35625,1.42083 0 0 0 1.24688,-0.91667l4.39688,0a0.45938,0.48125 0 0 0 0.48125,-0.45833a0.45938,0.48125 0 0 0 -0.48125,-0.45833z" />
                                                <path d="m9.14375,5.04167l-0.89688,0a1.35625,1.42083 0 0 0 -1.24688,-0.91667a1.3125,1.375 0 0 0 -1.225,0.91667l-4.41875,0a0.4375,0.45833 0 1 0 0,0.91667l4.41875,0a1.3125,1.375 0 0 0 1.225,0.91667a1.35625,1.42083 0 0 0 1.24688,-0.91667l0.89688,0a0.4375,0.45833 0 1 0 0,-0.91667z" />
                                                <path d="m9.14375,8.25l-3.74063,0a1.35625,1.42083 0 0 0 -1.24688,-0.91667a1.3125,1.375 0 0 0 -1.225,0.91667l-1.575,0a0.4375,0.45833 0 1 0 0,0.91667l1.575,0a1.3125,1.375 0 0 0 1.225,0.91667a1.35625,1.42083 0 0 0 1.24688,-0.91667l3.74063,0a0.4375,0.45833 0 1 0 0,-0.91667z" />
                                            </g>
                                        </svg>
                                        <input
                                            type="text"
                                            className="w-full text-sm font-normal border border-gray-300 rounded outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                            onChange={(e) => handleSearchFilterChange(column.key, e.target.value)}
                                        />
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    {/* Body của bảng */}
                    <tbody className="bg-white divide-y divide-gray-200">
                        {currentPageData.map((row, index) => (
                            <tr key={index} className='border-b odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 dark:border-gray-700'>
                                {tableColumns.map((column) => (
                                    <td key={column.key} className="px-3 py-3 overflow-hidden text-xs border border-gray-200 whitespace-nowrap text-ellipsis">
                                        {column.format ? column.format(row[column.key]) : row[column.key]}
                                    </td>
                                ))}
                                <td className="px-3 py-3 overflow-hidden border border-gray-200 whitespace-nowrap text-ellipsis text-exs">
                                    <button className="text-red-500 hover:text-red-700" onClick={() => handleOpenDeleteModal(row.id)}>
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Modal xác nhận xóa */}
                {isDeleteModalVisible && (
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                            <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
                                                Delete Member Site
                                            </h3>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Are you sure you want to delete this member site? This action cannot be undone.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button type="button" className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={handleDeleteMember}>
                                        Delete
                                    </button>
                                    <button type="button" className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={handleCloseDeleteModal}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MemberSiteManagement;
