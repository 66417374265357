/**
 * DailyRankChart Component
 * Component hiển thị biểu đồ treemap xếp hạng người dùng theo ngày
 * Sử dụng thư viện ApexCharts để vẽ biểu đồ treemap
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ApexCharts from 'react-apexcharts';
import { BarLoader } from 'react-spinners';
import { fetchDailyUserRankData, selectDailyUserRankData } from '../../../services/chartsSlice';

// Các hằng số cho style
const CHART_CONTAINER_STYLE = 'daily-rank-chart';
const CHART_HEIGHT = 280;
const CHART_OPTIONS_HEIGHT = 350;
const FONT_FAMILY = 'Poppins, sans-serif';
const FONT_SIZE = '12px';
const LABEL_COLOR = '#000000';
const LOADING_CONTAINER_STYLE = 'flex items-center justify-center h-[280px] bg-white rounded-lg';
const ERROR_CONTAINER_STYLE = 'flex items-center justify-center h-[280px] bg-white rounded-lg text-red-500';

// Mảng màu sắc cho biểu đồ
const CHART_COLORS = [
    "#5DA5DA", "#FAA43A", "#60BD68", "#F17CB0", "#B2912F",
    "#B276B2", "#DECF3F", "#F15854", "#4D4D4D", "#1F77B4",
    "#AEC7E8", "#FF7F0E", "#FFBB78", "#2CA02C", "#98DF8A",
    "#D62728", "#FF9896", "#9467BD", "#C5B0D5", "#8C564B"
];

function DailyRankChart() {
    // Khởi tạo dispatch và lấy dữ liệu từ Redux store
    const dispatch = useDispatch();
    const dailyUserRankData = useSelector(selectDailyUserRankData);
    const loading = useSelector(state => state.charts.loading.dailyUserRank);
    const error = useSelector(state => state.charts.error.dailyUserRank);

    // Fetch dữ liệu khi component mount
    useEffect(() => {
        dispatch(fetchDailyUserRankData());
    }, [dispatch]);

    // Lọc bỏ các user có total_coins = 0
    const filteredData = dailyUserRankData.filter(user => user.total_coins > 0);

    // Cấu hình cho biểu đồ treemap với 20 màu khác nhau
    const chartOptions = {
        chart: {
            height: CHART_OPTIONS_HEIGHT,
            type: 'treemap',
            toolbar: {
                show: false // Ẩn thanh công cụ
            }
        },
        colors: CHART_COLORS,
        plotOptions: {
            treemap: {
                distributed: true, // Phân phối màu cho từng item
                enableShades: false
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                fontFamily: FONT_FAMILY, // Font chữ Poppins
                fontSize: FONT_SIZE,
                colors: [LABEL_COLOR] // Màu chữ đen
            },
            formatter: function (value, { dataPointIndex }) {
                const user = filteredData[dataPointIndex];
                // Hiển thị username và total_coins
                return `${user.username} - $ ${user.total_coins}`;
            },
            useHTML: true // Sử dụng HTML để render labels
        }
    };

    // Tạo dữ liệu series cho biểu đồ
    const chartSeries = [{
        data: filteredData.map((user, index) => ({
            x: user.username,
            y: user.total_coins,
            fillColor: chartOptions.colors[index % chartOptions.colors.length] // Gán màu cho từng data point
        }))
    }];

    // Component hiển thị loading
    const LoadingIndicator = () => (
        <div className={LOADING_CONTAINER_STYLE}>
            <BarLoader color="#3592e8" />
        </div>
    );

    // Component hiển thị lỗi
    const ErrorIndicator = ({ message }) => (
        <div className={ERROR_CONTAINER_STYLE}>
            <p>Error loading the chart data: {message}</p>
        </div>
    );

    return (
        <div className={CHART_CONTAINER_STYLE}>
            {loading && <LoadingIndicator />}
            {error && <ErrorIndicator message={error.message} />}
            {!loading && !error && (
                <ApexCharts
                    options={chartOptions}
                    series={chartSeries}
                    type="treemap"
                    height={CHART_HEIGHT}
                />
            )}
        </div>
    );
}

export default DailyRankChart;
