/**
 * NetworkTableData Component
 * Component hiển thị bảng thống kê dữ liệu theo network
 * Bao gồm thông tin về network, doanh thu và số lượng lead
 */

import React from 'react';
import { useSelector } from 'react-redux';

// Các hằng số cho style
const CONTAINER_STYLE = 'bg-white rounded-sm';
const HEADER_STYLE = 'border-b p-1 border-gray-200 p-3 text-sm font-semibold';
const TABLE_STYLE = 'w-full divide-y divide-gray-200';
const TABLE_HEAD_BG_STYLE = 'bg-gray-50';
const TABLE_HEAD_CELL_STYLE = 'px-3 py-3 text-center text-xs font-bolder text-gray-500 uppercase tracking-wider';
const TABLE_CELL_STYLE = 'px-6 py-2 whitespace-nowrap text-sm text-center';
const TABLE_CELL_FONT_STYLE = 'font-medium text-gray-900';
const TABLE_CELL_VALUE_STYLE = 'text-gray-500';

const NetworkTableData = () => {
    // Lấy dữ liệu từ Redux store
    const networkTableData = useSelector(state => state.table.networkTableData);

    return (
        <div className={CONTAINER_STYLE}>
            {/* Header của bảng */}
            <div className={HEADER_STYLE}>
                Thống Kê Network
            </div>

            {/* Bảng dữ liệu */}
            <table className={TABLE_STYLE}>
                <thead className={TABLE_HEAD_BG_STYLE}>
                    <tr>
                        <th scope='col' className={TABLE_HEAD_CELL_STYLE}>
                            Network
                        </th>
                        <th scope='col' className={TABLE_HEAD_CELL_STYLE}>
                            Doanh thu
                        </th>
                        <th scope='col' className={TABLE_HEAD_CELL_STYLE}>
                            Lead
                        </th>
                    </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                    {networkTableData.map((entry, index) => (
                        <tr key={index}>
                            <td className={`${TABLE_CELL_STYLE} ${TABLE_CELL_FONT_STYLE}`}>
                                {entry.network}
                            </td>
                            <td className={`${TABLE_CELL_STYLE} ${TABLE_CELL_VALUE_STYLE}`}>
                                $ {entry.revenue}
                            </td>
                            <td className={`${TABLE_CELL_STYLE} ${TABLE_CELL_VALUE_STYLE}`}>
                                {entry.lead}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default NetworkTableData;
